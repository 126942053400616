import React, { useState } from 'react';
import imglogo from '../data/1.png';
import card_img from '../data/Calcium propionate.jpg';
import card_img2 from '../data/CP Granules.jpg';
import card_img3 from '../data/Gluten plus.jpg';
import card_img4 from '../data/Gluten Pure.jpg';
import card_img5 from '../data/Bread Protect Liquid.jpg';
import card_img6 from '../data/Sodium Propionate.jpg';
import card_img7 from '../data/Sorbic Acid.jpeg';
import card_img8 from '../data/Bread Improvers.jpg';
import card_img9 from '../data/Potassium Sorbate.jpg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';




function Food_ing() {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle function to open/close the sidebar
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
      return (
        <div className="home-page">
        {/* Hamburger Menu */}
        <div className="hamburger-menu">
          <div className="menu-icon" onClick={toggleSidebar}>
            {isOpen ? <CloseIcon sx={{ fontSize: 40 }}/> : <MenuIcon sx={{ fontSize: 40 }}/> }
          </div>
        </div>
      
        {/* Sidebar */}
        <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
          <div className="sidebar-close" onClick={toggleSidebar}>
            <CloseIcon sx={{ fontSize: 40 }} />
          </div>
          <ul className="sidebar-menu">
            <li>
              <Link to="gettoknowus" smooth={true} duration={500}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="card_container" smooth={true} duration={500}>
                Our Products
              </Link>
            </li>
            <li><a href="#">Our Technologies</a></li>
            <li><a href="ContactUs">Contact Us</a></li>
            <li><a href="Blog">Blogs</a></li>
          </ul>
        </div>
      
       {/* Navigation Bar Divided into Left and Right */}
       <nav className="enc_prod_nav">
          <div className="enc_prod_nav-left">
            <ul>
              <li>
                <Link to="gettoknowus" smooth={true} duration={500}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="card_container" smooth={true} duration={500}>
                  Our Products
                </Link></li>
            </ul>
          </div>
      
          {/* Centered Logo */}
          <div className="enc_prod_nav-center">
            <a href="Home">
              <img src={imglogo} alt="Ingredis Logo" className="home-page__logo" />
            </a>
          </div>
      
          <div className="enc_prod_nav-right">
            <ul>
              <li><a href="#">Our Technologies</a></li>
              <li><a href="ContactUs">Contact Us</a></li>
              <li><a href="Blog">Blogs</a></li>
            </ul>
          </div>
        </nav>
        <section className="products_section">
        <h2>Food Ingredients</h2>
        <div class="products_grid">
            <div class="product">
                <img src={card_img} alt="FORTICAP Calcium Propionate"/>
                <p>FORTICAP Calcium Propionate</p>
            </div>
            <div class="product">
                <img src={card_img2} alt="FORTICAP CP Granules"/>
                <p>FORTICAP CP Granules</p>
            </div>
            <div class="product">
                <img src={card_img3} alt="FORTICAP Gluten plus"/>
                <p>FORTICAP Gluten plus</p>
            </div>
            <div class="product">
                <img src={card_img4} alt="FORTICAP Gluten Pure"/>
                <p>FORTICAP Gluten Pure</p>
            </div>
            <div class="product">
                <img src={card_img5} alt="FORTICAP Bread Protect Liquid"/>
                <p>FORTICAP Bread Protect Liquid</p>
            </div>
            <div class="product">
                <img src={card_img6} alt="FORTICAP Sodium Propionate"/>
                <p>FORTICAP Sodium Propionate</p>
            </div>
            <div class="product">
                <img src={card_img7} alt="FORTICAP Sorbic Acid"/>
                <p>FORTICAP Sorbic Acid</p>
            </div>
            <div class="product">
                <img src={card_img8} alt="FORTICAP Bread Improvers"/>
                <p>FORTICAP Bread Improvers</p>
            </div><div class="product">
                <img src={card_img9} alt="FORTICAP Potassium Sorbate"/>
                <p>FORTICAP Potassium Sorbate</p>
            </div>
        </div>
    </section>

        </div>
    );
}

export default Food_ing;
