import React, { useState } from 'react';
import bgvid from '../data/Ingredis without text.mp4';
import imglogo from '../data/1.png';
import fooding from '../data/Gluten_pure.jpg';
import feeding from '../data/Feed.jpg';
import gum_acacia from '../data/Gum_acacia.jpg'
import enc_prod from '../data/Enc-prod.jpg';
import logopng from '../data/2.png';
import ContactUs from './about_us';
import Sidebar from './sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Element, animateScroll as scroll } from 'react-scroll';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to open/close the sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="home-page">
  {/* Hamburger Menu */}
  <div className="hamburger-menu">
    <div className="menu-icon" onClick={toggleSidebar}>
      {isOpen ? <CloseIcon sx={{ fontSize: 40 }}/> : <MenuIcon sx={{ fontSize: 40 }}/> }
    </div>
  </div>

  {/* Sidebar */}
  <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
    <div className="sidebar-close" onClick={toggleSidebar}>
      <CloseIcon sx={{ fontSize: 40 }} />
    </div>
    <ul className="sidebar-menu">
      <li>
        <Link to="gettoknowus" smooth={true} duration={500}>
          About Us
        </Link>
      </li>
      <li>
        <Link to="card_container" smooth={true} duration={500}>
          Our Products
        </Link>
      </li>
      <li><a href="#">Our Technologies</a></li>
      <li><a href="ContactUs">Contact Us</a></li>
      <li><a href="Blog">Blogs</a></li>
    </ul>
  </div>

 {/* Navigation Bar Divided into Left and Right */}
 <nav className="home-page__nav">
    <div className="nav-left">
      <ul>
        <li>
          <Link to="gettoknowus" smooth={true} duration={500}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="card_container" smooth={true} duration={500}>
            Our Products
          </Link></li>
      </ul>
    </div>

    {/* Centered Logo */}
    <div className="nav-center">
      <a href="/">
        <img src={imglogo} alt="Ingredis Logo" className="home-page__logo" />
      </a>
    </div>

    <div className="nav-right">
      <ul>
        <li><a href="#">Our Technologies</a></li>
        <li><a href="ContactUs">Contact Us</a></li>
        <li><a href="Blog">Blogs</a></li>
      </ul>
    </div>
  </nav>


      {/* Video Background */}
      <video src={bgvid} autoPlay loop muted className="home-page_bgvid" />

      {/* Get to Know Us Section (Moved Below Video) */}
      <Element name="gettoknowus">
  <section className="home-page__getto-knowus">
    <h1>Get to know Us!</h1>
    <div className="content-container">
      <div className="text-content">
        <p>
          At Ingredis, we are committed to delivering excellence in high-quality products and chemicals.
          With our headquarters based in India and a robust presence in Dubai and the Gulf, we bring together
          innovation, quality, and reliability to cater to the diverse needs of the food industry.
        </p>
      <img src={logopng} alt="Ingredis Logo" className="logo_png" />

        <p className='last-para'>
          Driven by a passion for excellence, Ingredis is dedicated to upholding the highest standards of
          safety and quality in every aspect of our operations. Our state-of-the-art manufacturing facilities
          ensure that our products meet stringent regulatory requirements, providing our customers with peace
          of mind and confidence in the safety of their food products.
        </p>
      </div>
    </div>
    <p2 className='italic'>
      At Ingredis, we believe that exceptional outcomes start with exceptional ingredients.
      Our dedication to quality and innovation ensures that every product we offer enhances your culinary creations and
      drives success in every kitchen.
    </p2>
    <div className="bar"></div>
  </section>
</Element>

      <section className='card_container'>
        <h1>
          Our Product Ranges
        </h1>
        <p>
          Our brand specializes in delivering top quality encapsulated products, food ingredients, feed
          ingredients and Gum Acacia.
        </p>
        <div className="card_holder">
          <div class="card">
          <a href="Food_ing">
            <img src={fooding} alt="foing-card" />
            <div class="card-content">
              <h2 class="card-title">Food Ingredients</h2>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="Food_ing" class="card-link">Read More</a> */}
            </div></a>
          </div>
          <div class="card">
            <a href="Feed_ing">
            <img src={feeding} alt="foing-card2" />
            <div class="card-content">
              <h3 class="card-title">Feed Ingredients</h3>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="Feed_ing" class="card-link">Read More</a> */}
            </div></a>
          </div>
          <div class="card">
            <img src={gum_acacia} alt="foing-card3" />
            <div class="card-content">
              <h4 class="card-title">Gum Acacia</h4>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="#" class="card-link">Read More</a> */}
            </div>
          </div>
          <div class="card">
            <a href="Enc_prod">
            <img src={enc_prod} alt="foing-card" />
            <div class="card-content">
              <h2 class="card-title">Encapsulated Products</h2>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="Enc_prod" class="card-link">Read More</a> */}
            </div></a>
          </div>
        </div>

      </section>
      <section className="container">
  <div className="left-side">
    <h1>INGREDIS GENERAL</h1>
    <img src={imglogo} alt="Ingredis Logo" className="logo-end" />
    <p className='bold'>Address:</p>
    <p2>1414, Sabha Building, Jabal Ali Industrial First, Dubai, UAE</p2>
    <p3 className='bold'>Email:</p3>
    <p4><a href="mailto:info@ingredis.ae">info@ingredis.ae</a></p4>
    <p5 className='bold'>Contact No:</p5>
    <p6><a href="tel:+971585926115">+971585926115</a>  , 
    <a href="tel:+971509208161">+971509208161</a></p6>
  </div>
  <div className="right-side">
    <h1>TRADING COMPANY</h1>
    <p className='bold'>
        <Link to="gettoknowus" smooth={true} duration={500}>
          About us
        </Link>
        </p>
    <p2>About Ingredis</p2>
    <p3>Our Mission</p3>
    <p4>Our Vision</p4>
    <p5>Value we Provide</p5>
    <p6 className='bold'>
      <Link to="card_container" smooth={true} duration={500}>
          Our Products
        </Link>
        </p6>
    <p7 className='bold'>Our Technologies</p7>
    <p8 className='bold'>Industries</p8>
    <p9 className='bold'>Blogs</p9>
  </div>
</section>
<div className="footer">
    <p7>©</p7>
    <p8>INGREDIS TRADING COMPANY</p8>
  </div>

    </div>
  );
}

export default Home;
