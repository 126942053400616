import React, { useState } from 'react';
import imglogo from '../data/1.png';
import card_img from '../components/FORTICAP CP.jpg';
import card_img2 from '../components/Sorbic Acid.jpg';
import card_img3 from '../components/Latic Acid.jpg';
import card_img4 from '../components/Citirc Acid.jpeg';
import card_img5 from '../components/Fumaric Acid.jpeg';
import card_img6 from '../components/Sodium Propiopnate.jpg';
import card_img7 from '../components/Malic Acid.jpeg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


function Feed_ing() {
      const [isOpen, setIsOpen] = useState(false);

      // Toggle function to open/close the sidebar
      const toggleSidebar = () => {
        setIsOpen(!isOpen);
      };
        return (
          <div className="home-page">
          {/* Hamburger Menu */}
          <div className="hamburger-menu">
            <div className="menu-icon" onClick={toggleSidebar}>
              {isOpen ? <CloseIcon sx={{ fontSize: 40 }}/> : <MenuIcon sx={{ fontSize: 40 }}/> }
            </div>
          </div>
        
          {/* Sidebar */}
          <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
            <div className="sidebar-close" onClick={toggleSidebar}>
              <CloseIcon sx={{ fontSize: 40 }} />
            </div>
            <ul className="sidebar-menu">
              <li>
                <Link to="gettoknowus" smooth={true} duration={500}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="card_container" smooth={true} duration={500}>
                  Our Products
                </Link>
              </li>
              <li><a href="#">Our Technologies</a></li>
              <li><a href="ContactUs">Contact Us</a></li>
              <li><a href="Blog">Blogs</a></li>
            </ul>
          </div>
        
         {/* Navigation Bar Divided into Left and Right */}
         <nav className="enc_prod_nav">
            <div className="enc_prod_nav-left">
              <ul>
                <li>
                  <Link to="gettoknowus" smooth={true} duration={500}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="card_container" smooth={true} duration={500}>
                    Our Products
                  </Link></li>
              </ul>
            </div>
        
            {/* Centered Logo */}
            <div className="enc_prod_nav-center">
              <a href="Home">
                <img src={imglogo} alt="Ingredis Logo" className="home-page__logo" />
              </a>
            </div>
        
            <div className="enc_prod_nav-right">
              <ul>
                <li><a href="#">Our Technologies</a></li>
                <li><a href="ContactUs">Contact Us</a></li>
                <li><a href="Blog">Blogs</a></li>
              </ul>
            </div>
          </nav>
        <section className="products_section">
        <h2>Feed Ingredients</h2>
        <div class="products_grid">
            <div class="product">
                <img src={card_img} alt="FORTICAP Calcium Propionate"/>
                <p>FORTICAP Calcium Propionate</p>
            </div>
            <div class="product">
                <img src={card_img2} alt="FORTICAP Sorbic Acid"/>
                <p>FORTICAP Sorbic Acid</p>
            </div>
            <div class="product">
                <img src={card_img3} alt="FORTICAP Lactic Acid"/>
                <p>FORTICAP Lactic Acid</p>
            </div>
            <div class="product">
                <img src={card_img4} alt="FORTICAP Citric Acid"/>
                <p>FORTICAP Citric Acid</p>
            </div>
            <div class="product">
                <img src={card_img5} alt="FORTICAP Fumaric Acid"/>
                <p>FORTICAP Fumaric Acid</p>
            </div>
            <div class="product">
                <img src={card_img6} alt="FORTICAP Sodium Propionate"/>
                <p>FORTICAP Sodium Propionate</p>
            </div>
            <div class="product">
                <img src={card_img7} alt="FORTICAP Malic Acid"/>
                <p>FORTICAP Malic Acid</p>
            </div>
        </div>
    </section>

        </div>
    );
}

export default Feed_ing;
