// import React from 'react';
// import { Route, Routes, Link } from 'react-router-dom';
// import Home from './components/Home';
// import Blog from './components/blog'; // Capitalize 'Blog'
// import Enc_prod from './components/Enc_prod';
// import Food_ing from './components/Food_ing';
// import Feed_ing from './components/Feed_ing';
// import ContactUs from './components/about_us';

// import './components/Home.css';

// function App() {
//   return (
//       <div>
//         {/* Optional: Add navigation links */}
//         {/* <nav>
//           <ul>
//             <li><Link to="/">Home</Link></li>
//             <li><Link to="/blog">Blog</Link></li>
//             <li><Link to="/Enc_prod">Enc_prod</Link></li>
//             <li><Link to="/Food_ing">Food_ing</Link></li>
//             <li><Link to="/Feed_ing">Feed_ing</Link></li>
//           </ul>
//         </nav> */}
//         <Routes>
//   <Route index path='/Home' element={<Home />} />
//   <Route path='/blog' element={<Blog />} />
//   <Route path='/Enc_prod' element={<Enc_prod />} />
//   <Route path='/Food_ing' element={<Food_ing />} />
//   <Route path='/Feed_ing' element={<Feed_ing />} />
//   <Route path='/ContactUs' element={<ContactUs />} />
// </Routes>
//         {/* <Routes>
//           <Route index path='/Home' element={<Home />} />
//           <Route path='/blog' element={<Blog />} /> {/* Capitalize 'Blog' */}
//           {/* <Route path='/Enc_prod' element={<Enc_prod />} />
//           <Route path='/Food_ing' element={<Food_ing />} />
//           <Route path='/Feed_ing' element={<Feed_ing />} />
//           <Route path='/ContactUs' element={<ContactUs />} />
//         </Routes> */}
//       </div>
//   );
// }

// export default App;
import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Blog from './components/blog'; // Capitalize 'Blog'
import Enc_prod from './components/Enc_prod';
import Food_ing from './components/Food_ing';
import Feed_ing from './components/Feed_ing';
import ContactUs from './components/about_us';

import './components/Home.css';

function App() {
  return (
    <div>
      {/* Optional: Add navigation links
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/Enc_prod">Enc_prod</Link></li>
          <li><Link to="/Food_ing">Food_ing</Link></li>
          <li><Link to="/Feed_ing">Feed_ing</Link></li>
          <li><Link to="/ContactUs">Contact Us</Link></li>
        </ul>
      </nav> */}

      <Routes>
        <Route index path='/' element={<Home />} /> {/* Home path */}
        <Route path='#' element={<Blog />} /> {/* Blog route */}
        <Route path='/Enc_prod' element={<Enc_prod />} /> {/* Enc_prod route */}
        <Route path='/Food_ing' element={<Food_ing />} /> {/* Food_ing route */}
        <Route path='/Feed_ing' element={<Feed_ing />} /> {/* Feed_ing route */}
        <Route path='/ContactUs' element={<ContactUs />} /> {/* Contact Us route */}
      </Routes>
    </div>
  );
}

export default App;

