import React from 'react';
import imglogo from '../data/1.png'; // Import for the logo image
import feeding from '../data/Feed.jpg';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


function Blog() {
    return (
        <div className="enc_prod_pg">
        {/* <Sidebar/> */}
      <div className="ing_logo">
        <a href="Home">
          <img src={imglogo} alt="Ingredis Logo" className="home-page__logo" />
        </a>
      </div>
      <MenuIcon className='menu' sx={{ fontSize: 40 }}/>
      <nav className="home-page__nav">
        <ul>
          <li>
            <Link to="home-page__getto-knowus" smooth={true} duration={500}>
                About Us
            </Link>
          </li>
          <li><a href="Food_ing">Our Products</a></li>
          <li><a href="#">Our Technologies</a></li>
          <li><a href="ContactUs">Contact Us</a></li>
          <li><a href="Blog">Blogs</a></li>
        </ul>
      </nav>
            <div className="title">
                <h1>BLOG</h1>
            </div>
            <section className='bpg_content'>
                <div className="card_holder">
                    <div className="card_bpg">
                        <img src={feeding} alt="Food Ingredients" />
                        <div className="card-content">
                            <h2 className="card-title">Food Ingredients</h2>
                            <p className="card-text">Thisented by the card.</p>
                            <a href="#" className="card-link">Read More</a>
                        </div>
                    </div>
                    <div className="card_bpg">
                        <img src={feeding} alt="Food Ingredients" />
                        <div className="card-content">
                            <h2 className="card-title">Food Ingredients</h2>
                            <p className="card-text">Thisented by the card.</p>
                            <a href="#" className="card-link">Read More</a>
                        </div>
                    </div>
                    <div className="card_bpg">
                        <img src={feeding} alt="Food Ingredients" />
                        <div className="card-content">
                            <h2 className="card-title">Food Ingredients</h2>
                            <p className="card-text">Thisented by the card.</p>
                            <a href="#" className="card-link">Read More</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blog;
